import React, { Component, Fragment } from "react";
import {
	Layout,
	Modal,
	Typography,
	Row,
	Col,
	Divider,
	Tag,
	Spin,
	Input,
	Button,
} from "antd";
import axios from "axios";
import moment from "moment";
import "./App.css";

import logo from "./logo.png";

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agentView: true,
			loading: false,
			notFound: false,
			type: null,
			insurance: null,
			client: null,
		};
	}

	componentDidMount() {
		// const vars = this.getUrlVars();
		// if (!vars.id || !vars.type) {
		// 	return this._notFound();
		// }
		// this._fetch(vars.id, vars.type, vars.agent);
	}

	_notFound = () => {
		this.setState({
			notFound: true,
			loading: false,
		});
	};

	_fetch = (id, type, agent) => {
		axios
			.get(
				"https://us-central1-all-in-one-crm-b8cdf.cloudfunctions.net/insurance?id=" +
					id +
					"&type=" +
					type +
					"-insurance"
			)
			.then((res) => {
				this.setState({
					agentView:
						Boolean(agent) && res.data.insurance?.["agentName"],
					type: type,
					insurance: res.data.insurance,
					client: res.data.client,
					loading: false,
				});
			})
			.catch((e) => {
				if (e.response.status === 404) {
					this.setState({
						loading: false,
						notFound: true,
					});
				} else {
					Modal.warn({
						title: "We found an error",
						content: e.response.data,
					});
				}
			});
	};

	_fetchPolicy = (insuranceID) => {
		this.setState({ loading: true });
		const type = insuranceID.toUpperCase().startsWith("C")
			? "car"
			: "truck";
		axios
			.get(
				"https://us-central1-all-in-one-crm-b8cdf.cloudfunctions.net/insurance?insuranceID=" +
					insuranceID +
					"&type=" +
					type +
					"-insurance"
			)
			.then((res) => {
				this.setState({
					agentView:
						Boolean(this.state.agent) &&
						res.data.insurance?.["agentName"],
					type: type,
					insurance: res.data.insurance,
					client: res.data.client,
					loading: false,
				});
			})
			.catch((e) => {
				if (e.response.status === 404) {
					this.setState({
						loading: false,
						notFound: true,
					});
				} else {
					Modal.warn({
						title: "We found an error",
						content: e.response.data,
					});
				}
			});
	};

	getUrlVars = () => {
		let vars = {};
		window.location.href.replace(
			/[?&]+([^=&]+)=([^&]*)/gi,
			function (m, key, value) {
				vars[key] = value;
			}
		);
		return vars;
	};

	content = () => {
		if (!this.state.insurance) {
			return (
				<Fragment>
					<Typography.Title align={"center"} level={1}>
						Find Policy
					</Typography.Title>
					<Typography.Title align={"center"} level={3}>
						Please enter the insurance ID
					</Typography.Title>
					<div style={{ textAlign: "center" }}>
						<Input.Search
							style={{ maxWidth: 350 }}
							enterButton="Search"
							loading={this.state.loading}
							onSearch={(e) => {
								if (e) {
									this._fetchPolicy(e);
								}
							}}
						/>
					</div>
				</Fragment>
			);
		}
		if (this.state.notFound) {
			return (
				<Fragment>
					<Typography.Title align={"center"} level={1}>
						Not Found
					</Typography.Title>
					<Typography.Title align={"center"} level={3}>
						We were not able to find this insurance. <br /> Try
						scanning again.
					</Typography.Title>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<Row>
						{this.state.agentView && (
							<>
								<Col span={24}>
									<Typography.Title level={2}>
										Agent
									</Typography.Title>
									<Typography.Title level={4}>
										{this.state.insurance?.["agentName"]}
									</Typography.Title>
								</Col>
								<br />
								<br />
								<Divider />
								<br />
								<br />
								<br />
							</>
						)}
						<Col xs={24} lg={8}>
							<Typography.Title level={2}>
								Status
							</Typography.Title>
							{this.state.insurance["void"] ? (
								<Tag
									style={{ fontSize: 16, padding: 5 }}
									color={"red"}
								>
									Void
								</Tag>
							) : moment(
									this.state.insurance.to["_seconds"],
									"X"
							  ).isBefore(moment()) ? (
								<Tag
									style={{ fontSize: 16, padding: 5 }}
									color={"red"}
								>
									Expired
								</Tag>
							) : (
								<Tag
									style={{ fontSize: 16, padding: 5 }}
									color={"green"}
								>
									Active
								</Tag>
							)}
							<br />
							<br />
						</Col>
						<Col xs={24} lg={8}>
							<Typography.Title level={2}>
								Insurance Number
							</Typography.Title>
							<Typography.Text>
								{this.state.insurance["insuranceID"]}
							</Typography.Text>
							<br />
							<br />
						</Col>
						<Col xs={24} lg={8}>
							<Typography.Title level={2}>
								Expiration Date
							</Typography.Title>
							<Typography.Text>
								{moment(
									this.state.insurance.to["_seconds"],
									"X"
								).format("lll")}
							</Typography.Text>
							<br />
							<br />
						</Col>
					</Row>
					<br />
					<Divider />
					<br />
					<Row>
						<Col xs={24} lg={8}>
							<Typography.Title level={2}>
								Named Insured
							</Typography.Title>
							<Typography.Text>
								{this.state.client.name} <br />
								{this.state.client.address} <br />
								{this.state.client.city},{" "}
								{this.state.client.state}{" "}
								{this.state.client["zip"]}{" "}
								{this.state.client.country} <br />
							</Typography.Text>
							<br />
							<br />
						</Col>
						<Col xs={24} lg={8}>
							<Typography.Title level={2}>
								Included Drivers
							</Typography.Title>
							<Typography.Text>
								{this.state.client.name}
							</Typography.Text>
							<br />
							<br />
						</Col>
						<Col xs={24} lg={8}>
							<Typography.Title level={2}>
								Effective Date
							</Typography.Title>
							<Typography.Text>
								{moment(
									this.state.insurance.from["_seconds"],
									"X"
								).format("lll")}
							</Typography.Text>
							<br />
							<br />
						</Col>
					</Row>
					<br />
					<Divider />
					<br />
					<Row>
						<Col xs={24} lg={24}>
							<Typography.Title level={2}>
								Vehicles
							</Typography.Title>
						</Col>
						<Col xs={24} lg={5}>
							<Typography.Title level={4}>Year</Typography.Title>
							<Typography.Text>
								{this.state.insurance["year"]} <br />
								{this.state.insurance["yearExtra"]} <br />
							</Typography.Text>
							<br />
							<br />
						</Col>
						<Col xs={24} lg={11}>
							<Typography.Title level={4}>
								Make / Model / Body Style
							</Typography.Title>
							<Typography.Text>
								{this.state.insurance["make"]}{" "}
								{this.state.insurance["model"]} <br />
								{this.state.insurance["makeExtra"]}{" "}
								{this.state.insurance["modelExtra"]} <br />
							</Typography.Text>
							<br />
							<br />
						</Col>
						<Col xs={24} lg={8}>
							<Typography.Title level={4}>VIN</Typography.Title>
							<Typography.Text>
								{this.state.insurance["vin"]} <br />
								{this.state.insurance["vinExtra"]}
							</Typography.Text>
							<br />
							<br />
						</Col>
					</Row>
				</Fragment>
			);
		}
	};

	render() {
		return (
			<div className="App">
				<Layout className="layout">
					<Layout.Header />
					<Layout.Content style={{ padding: "0 5vw" }}>
						{!this.state.agentView && (
							<img
								alt={"All You Need Insurance"}
								src={logo}
								style={{
									margin: 20,
									width: "60%",
									maxWidth: 200,
								}}
							/>
						)}
						{this.state.agentView && (
							<div style={{ margin: 20, height: 20 }} />
						)}
						<div
							style={{
								background: "#fff",
								padding: 50,
								textAlign: this.state.loading
									? "center"
									: "left",
								borderRadius: 10,
							}}
						>
							{this.state.loading ? (
								<Spin
									style={{ fontSize: 36, color: "#11489F" }}
								/>
							) : (
								this.content()
							)}
						</div>
					</Layout.Content>
					<Layout.Footer style={{ textAlign: "center" }}>
						Developed by:{" "}
						<a
							rel={"noreferrer"}
							href={"https://studiomediaagency.com"}
							target={"_blank"}
						>
							Studio Media Agency
						</a>
					</Layout.Footer>
				</Layout>
			</div>
		);
	}
}
